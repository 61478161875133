// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Jost\";font-style:normal;font-weight:400;src:local(\"☺\"),url(/fonts/Jost/Jost-Regular.woff2) format(\"woff2\"),url(/fonts/Jost/Jost-Regular.woff) format(\"woff\")}.tqAXz2dh{display:flex;flex-direction:column;flex-grow:1}.aVxq2pWF{display:flex;flex-wrap:wrap;font-size:max(.88rem,min(.83vw,1rem));gap:.5em 1em;justify-content:flex-end}@media(max-width:667.98px){.aVxq2pWF{justify-content:flex-start}}.PYVW-jm6{align-items:center;display:flex}@media(hover:hover){.PYVW-jm6:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.PYVW-jm6:active{-webkit-text-decoration:underline;text-decoration:underline}}.OfjIdC36{--size:1.2em;margin-right:.5em}.Ijhsf-DE{font-size:.88rem;font-weight:400;line-height:1;opacity:.6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "tqAXz2dh",
	"pdfs": "aVxq2pWF",
	"pdf": "PYVW-jm6",
	"pdfIcon": "OfjIdC36",
	"subheading": "Ijhsf-DE"
};
module.exports = ___CSS_LOADER_EXPORT___;
