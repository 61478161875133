// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eJtcpGnM{align-items:center;display:flex;justify-content:space-between;padding:var(--spacing-md) 0;position:relative}.eJtcpGnM>*+*{margin-left:max(1.13rem,min(1.67vw,2rem))}@media(max-width:667.98px){.eJtcpGnM{align-items:flex-start;flex-direction:column;justify-content:flex-start;padding:var(--spacing-sm) 0}.eJtcpGnM>*+*{margin-left:0;margin-top:max(1.13rem,min(1.67vw,2rem))}}.OZR20FLK{color:var(--color-bg-invert);font-size:2.5rem;font-weight:700;line-height:1.47}@media(max-width:667.98px){.OZR20FLK{font-size:1.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"header": "eJtcpGnM",
	"title": "OZR20FLK"
};
module.exports = ___CSS_LOADER_EXPORT___;
