// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8\\+K\\+UFxM{display:flex;flex-direction:column;flex-grow:1}.ZU1eHTjl{display:flex;margin-bottom:var(--spacing-lg);overflow-x:auto}@media(max-width:667.98px){.ZU1eHTjl{margin-bottom:var(--spacing-sm)}}.ZU1eHTjl>:not(:last-child){margin-right:var(--spacing-s)}.ZU1eHTjl a{border:1px solid var(--color-primary-on-bg);border-radius:var(--border-radius);color:var(--color-bg-invert);font-size:.88rem;font-weight:600;letter-spacing:.75px;line-height:1;padding:.54em .86em;transition:.1s linear;transition-property:all;white-space:nowrap}@media(max-width:667.98px){.ZU1eHTjl a{font-size:.75rem}}@media(hover:hover){.ZU1eHTjl a:hover{background:var(--color-primary-on-bg);border-color:var(--color-primary-on-bg);color:var(--color-bg)}}@media(hover:none){.ZU1eHTjl a:active{background:var(--color-primary-on-bg);border-color:var(--color-primary-on-bg);color:var(--color-bg)}}.ZU1eHTjl a.nuxt-link-active{background:var(--color-primary-on-bg);border-color:var(--color-primary-on-bg);color:var(--color-bg);cursor:default}._3QGZ1lB6{display:flex;flex-grow:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_8+K+UFxM",
	"days": "ZU1eHTjl",
	"spinnerContainer": "_3QGZ1lB6"
};
module.exports = ___CSS_LOADER_EXPORT___;
